<template>
  <div>
    <!-- Título principal -->
    <div
      class="d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row align-start mb-10 my-8"
    >
      <v-col class="d-flex" cols="1">
        <v-btn icon rounded class="mt-2" @click="$router.go(-1)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dense v-bind="attrs" v-on="on">
                <v-icon size="28">mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span>Volver</span>
          </v-tooltip>
        </v-btn>
        <div class="d-flex">
          <h3>
            {{ doctor.Nombre }}
          </h3>
          <div>
            <v-tooltip bottom v-if="licen.Var37 == 1">
              <!-- Botón buscar -->
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="ml-2 mt-2"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="activeShowDoctores = true"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              <span>Buscar médicos</span>
            </v-tooltip>
          </div>
        </div>
      </v-col>
      <v-col
        xs="12"
        sm="11"
        md="11"
        xl="11"
        lg="11"
        class="d-flex justify-center"
      >
        <h1 class="justify-center">Doctores y personal</h1>
      </v-col>
    </div>
    <!-- Contenido principal -->
    <div v-if="licen.Var1 == 1">
      <!-- Tabla con doctores -->
      <v-card class="mt-5 mx-2 pa-3 rounded-xl" v-if="activeShowDoctores">
        <div class="pa-3">
          <!-- Acción cerrar -->
          <div class="d-flex justify-end">
            <v-btn
              icon
              color="primary"
              @click="activeShowDoctores = false"
              class="mr-2"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon dense v-bind="attrs" v-on="on">
                    <v-icon color="primary">mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Cerrar</span>
              </v-tooltip>
            </v-btn>
          </div>
          <!-- Información de la tabla -->
          <div width="100%" class="mx-2 mb-5">
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              >
              </v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="doctors"
              :search="search"
              :footer-props="{
                'items-per-page-text': 'Filas por página',
              }"
            >
              <template v-slot:item.action="{ item }">
                <div class="actions">
                  <v-btn
                    class="ma-1"
                    icon
                    @click="btnSelectDoctorEditTime(item)"
                  >
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-check-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Gestionar horario</span>
                    </v-tooltip>
                  </v-btn>
                </div>
              </template>
              <template v-slot:no-data>
                <v-alert :value="true"> No hay resultados disponibles </v-alert>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card>
      <!-- Registro de especialista y horario -->
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-row class="mt-8">
          <!-- Registro de dr.  -->
          <v-col xs="12" sm="12" md="6" lg="6" xl="6" class="my-5">
            <div class="pa-3">
              <div class="d-flex justify-center mb-6">
                <div v-if="!doctor.Nombre">
                  <h2>Registro de nuevo doctor</h2>
                </div>
                <div v-else>
                  <h2>Actualizar doctor</h2>
                </div>
              </div>
              <v-alert
                class="ma-1"
                v-if="error != ''"
                border="left"
                colored-border
                type="error"
                elevation="2"
              >
                {{ error }}
              </v-alert>
              <div
                class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
              >
                <!-- Rut del doctor -->
                <v-text-field
                  v-model="rut"
                  class="ma-1"
                  name="rut"
                  ref="rut"
                  id="rut"
                  type="text"
                  @input="validateRut"
                  :rules="[rules.required]"
                  outlined
                  dense
                  label="Rut"
                  required
                  autofocus
                />
                <!-- Nombre del doctor -->
                <v-text-field
                  v-model="doctor.Nombre"
                  dense
                  :rules="[rules.required]"
                  outlined
                  label="Nombre"
                  class="ma-1"
                ></v-text-field>
              </div>
              <div>
                <v-text-field
                  v-model="doctor.Direccion"
                  dense
                  :rules="[rules.required]"
                  outlined
                  label="Dirección"
                  class="ma-1"
                ></v-text-field>
              </div>
              <div
                class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
              >
                <v-text-field
                  v-model="doctor.Fono"
                  dense
                  :rules="[rules.required]"
                  outlined
                  label="Teléfono"
                  class="ma-1"
                ></v-text-field>
                <v-text-field
                  v-model="doctor.Email"
                  dense
                  :rules="[rules.required]"
                  outlined
                  label="Correo electrónico"
                  class="ma-1"
                ></v-text-field>
              </div>
              <!-- registro -->
              <div
                class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
              >
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="doctor.Fecha_ingreso"
                      label="Fecha de ingreso"
                      prepend-icon="mdi-calendar"
                      class="ma-1"
                      outlined
                      :rules="[rules.required]"
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="doctor.Fecha_ingreso"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <!-- especialidad -->
              <div>
                <v-select
                  v-model="doctor.Especialidad"
                  class="ma-1"
                  outlined
                  dense
                  :rules="[rules.required]"
                  :items="especialidades"
                  item-text="Nespecialidad"
                  label="Seleccione especialidad"
                ></v-select>
              </div>
              <!-- imagen del doctor -->
              <div class="d-flex">
                <v-textarea
                  class="ma-1"
                  v-model="doctor.Descripcion"
                  outlined
                  rows="8"
                  dense
                  label="Descripción para mostrar en la web"
                ></v-textarea>
                <v-file-input
                  accept="image/*"
                  label="Imagen de perfil"
                  outlined
                  counter
                  
                  v-model="imgdoctorsrc"
                  prepend-icon="mdi-file-image-outline"
                  :show-size="1000"
                  class="border-edit ma-1"
                  height="100%"
                >
                  <template v-slot:selection="{ text }">
                    <v-avatar tile size="150">
                      <img v-if="imgdoctorsrc" :src="getUrlVariant()" />
                      <v-chip color="primary" dark label small>
                        {{ text }}
                      </v-chip>
                    </v-avatar>
                  </template>
                </v-file-input>
                
              </div>
         

              <div class="d-flex justify-end">
                <v-btn
                  color="primary"
                  :loading="activeUploadingCreateDoctor"
                  :disabled="activeUploadingCreateDoctor"
                  @click="btnCreateDoctor"
                  class="mx-1 text-capitalize rounded-lg"
                >
                  <div v-if="selectDoctor">Actualizar</div>
                  <div v-else>Crear</div>
                </v-btn>
              </div>
            </div>
          </v-col>
          <!-- División vista escritorio -->
          <v-divider vertical class="mb-5"></v-divider>
          <!-- Gestión de horario -->
          <v-col xs="12" sm="12" md="6" lg="6" xl="6" class="my-5">
            <!-- División vista móvil -->
            <v-divider
              class="mb-8 mx-5 d-flex d-sm-flex d-md-none d-lg-none d-xl-none"
            ></v-divider>
            <div class="d-flex flex-column justify-center align-center mb-6">
              <h2 class="mt-1 mb-1">Gestión de horarios</h2>
              <div class="d-flex">
                <h3 class="black_h3 mt-2">Profesional:</h3>
                <h3 v-if="doctor.Nombre" class="ml-1 mt-2">
                  {{ doctor.Nombre }}
                </h3>
                <v-btn v-if="doctor.Nombre" icon @click="cleanDoctor"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </div>
            </div>
            <!-- Formulario de gestión de horas -->
            <div v-if="selectDoctor">
              <div class="pa-3">
                <div>
                  <h3 class="ma-1 mb-2 black_h3">Gestión de días</h3>
                  <!-- chips con días -->
                  <div class="ma-1 mb-5">
                    <div v-if="doctor.Horariospersonal.length > 0">
                      <v-chip-group v-model="selection">
                        <v-chip
                          v-for="horario in doctor.Horariospersonal"
                          :key="horario.id"
                          color="primary"
                          outlined
                          close
                          @click:close="
                            btnSelectDeleteHorariopersonalByRutVar1(
                              horario.Var1
                            )
                          "
                          :value="size"
                        >
                          {{ horario.Dia }} desde
                          {{ horario.Desde.substring(11, 16) }} a las
                          {{ horario.Hasta.substring(11, 16) }}
                        </v-chip>
                      </v-chip-group>
                      <div class="d-flex justify-end">
                        <v-btn
                          outlined
                          color="primary"
                          @click="btnSelectDeleteHorariopersonalByRut"
                          class="text-capitalize rounded-lg"
                        >
                          Eliminar todos</v-btn
                        >
                      </div>
                    </div>
                  </div>
                  <v-select
                    :items="days"
                    item-text="name"
                    item-value="number"
                    v-model="selectDay"
                    outlined
                    dense
                    label="Día"
                    class="ma-1"
                  ></v-select>
                  <h3 class="ma-1 mb-2 black_h3">Horario desde y hasta</h3>
                  <div class="d-flex">
                    <v-menu
                      ref="menuStart"
                      v-model="menuStartTime"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="timeStart"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="timeStart"
                          outlined
                          dense
                          class="ma-2"
                          label="Inicio"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menuStartTime"
                        format="24hr"
                        v-model="timeStart"
                        full-width
                        @click:minute="$refs.menuStart.save(timeStart)"
                      ></v-time-picker>
                    </v-menu>
                    <v-menu
                      ref="menuEnd"
                      v-model="menuEndTime"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="timeEnd"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="timeEnd"
                          outlined
                          dense
                          class="ma-2"
                          label="Término"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menuEndTime"
                        v-model="timeEnd"
                        format="24hr"
                        full-width
                        :min="minTimeEnd"
                        @click:minute="$refs.menuEnd.save(timeEnd)"
                      ></v-time-picker>
                    </v-menu>
                  </div>
                  <!-- Botones de acción -->
                  <div class="d-flex flex-nowrap justify-end mb-4">
                    <v-btn
                      :loading="activeUploadingCreateHorariopersonal"
                      :disabled="
                        !selectDay ||
                        !timeStart ||
                        !timeEnd ||
                        activeUploadingCreateHorariopersonal
                      "
                      color="primary"
                      class="mr-2 text-capitalize rounded-lg"
                      @click="btnCrearhorariopersonal"
                    >
                      Grabar día</v-btn
                    >
                  </div>
                </div>
                <!-- Eliminación de día -->
                <v-dialog
                  v-model="activeDialogoDeleteHorarioByRutVar"
                  max-width="500px"
                  class="ma-8"
                  persistent
                >
                  <v-card class="rounded-xl">
                    <div class="pa-3">
                      <v-card-title class="title-1 text--center"
                        >¿Seguro(a) que quiere que quiere eliminar el día
                        seleccionado?
                      </v-card-title>
                      <v-card-actions class="d-flex justify-end">
                        <v-btn
                          color="primary"
                          outlined
                          @click="activeDialogoDeleteHorarioByRutVar = false"
                          class="text-capitalize rounded-lg"
                          >Cancelar</v-btn
                        >
                        <v-btn
                          color="primary"
                          @click="btnConfirmDeleteHorariopersonal"
                          class="text-capitalize rounded-lg ml-3"
                          >Confirmar</v-btn
                        >
                      </v-card-actions>
                    </div>
                  </v-card>
                </v-dialog>
                <!-- Eliminación todos los días -->
                <v-dialog
                  v-model="activeDialogoDeleteHorarioByRut"
                  max-width="500px"
                  class="ma-8"
                  persistent
                >
                  <v-card class="rounded-xl">
                    <div class="pa-3">
                      <v-card-title class="title-1 text--center"
                        >¿Seguro(a) que quiere eliminar todos los días ?
                      </v-card-title>
                      <v-card-actions class="d-flex justify-end">
                        <v-btn
                          color="primary"
                          outlined
                          @click="activeDialogoDeleteHorarioByRut = false"
                          class="text-capitalize rounded-lg"
                          >Cancelar</v-btn
                        >
                        <v-btn
                          color="primary"
                          @click="btnConfirmDeleteHorariopersonal"
                          class="text-capitalize rounded-lg ml-3"
                          >Confirmar</v-btn
                        >
                      </v-card-actions>
                    </div>
                  </v-card>
                </v-dialog>
                <v-divider class="mx-3 my-2"></v-divider>
                <!-- Gestión de la programación de la agenda -->
                <div class="mt-5">
                  <h3 class="mb-2 black_h3">
                    Programación de horas disponibles
                  </h3>
                  <!-- Selección de intervalo de tiempo -->
                  <div>
                    <v-select
                      v-model="selectInterval"
                      :items="timeInterval"
                      item-text="text"
                      item-value="value"
                      outlined
                      dense
                      class="ma-1"
                      placeholder="Intervalo de tiempo"
                    ></v-select>
                  </div>
                  <div>
                    <v-select
                      v-model="selectSucursalProgramacion"
                      :items="sedes"
                      item-text="Origen"
                      item-value="Origen"
                      outlined
                      dense
                      class="ma-1"
                      placeholder="Sucursal"
                    ></v-select>
                  </div>
                  <!-- Fecha desde y cuántas semanas -->
                  <h3 class="mb-2 black_h3">Fecha desde y cuántas semanas</h3>
                  <div
                    class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
                  >
                    <v-menu
                      v-model="menuQuantityWeek"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateInicio"
                          label="Fecha inicio"
                          outlined
                          dense
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          class="ma-1"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateInicio"
                        @input="menuQuantityWeek = false"
                      ></v-date-picker>
                    </v-menu>
                    <v-select
                      :items="days"
                      item-text="name"
                      item-value="number"
                      v-model="selectDay"
                      outlined
                      dense
                      label="Día"
                      class="ma-1"
                    ></v-select>
                    <v-text-field
                      dense
                      outlined
                      type="number"
                      :min="minweek"
                      v-model="timeweek"
                      label="Cantidad de semanas"
                      class="ma-1"
                      placeholder="Cantidad"
                    ></v-text-field>
                  </div>
                  <div class="d-flex">
                    <v-menu
                      ref="menuStartPrograming"
                      v-model="menuStartTimePrograming"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="timeStart"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="timeStart"
                          outlined
                          dense
                          class="ma-2"
                          label="Inicio programacion"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menuStartTimePrograming"
                        format="24hr"
                        v-model="timeStart"
                        full-width
                        @click:minute="
                          $refs.menuStartPrograming.save(timeStart)
                        "
                      ></v-time-picker>
                    </v-menu>
                    <v-menu
                      ref="menuEndPrograming"
                      v-model="menuEndTimePrograming"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="timeEnd"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="timeEnd"
                          outlined
                          dense
                          class="ma-2"
                          label="Término programacion"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menuEndTimePrograming"
                        v-model="timeEnd"
                        format="24hr"
                        full-width
                        :min="minTimeEnd"
                        @click:minute="$refs.menuEndPrograming.save(timeEnd)"
                      ></v-time-picker>
                    </v-menu>
                  </div>
                  <!-- Acciones programación (botones inferiores) -->
                  <div class="d-flex justify-end btnContainer">
                    <div
                      class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row mb-2 justify-end"
                    >
                      <v-btn
                        color="primary"
                        class="mr-2 rounded-lg text-capitalize"
                        @click="btnCreateProgramacion"
                        :loading="activeUploadingCreateProgramacion"
                        :disabled="
                          !selectInterval ||
                          !dateInicio ||
                          !selectDay ||
                          !timeStart ||
                          !timeEnd ||
                          !selectSucursalProgramacion ||
                          activeUploadingCreateProgramacion
                        "
                      >
                        Grabar</v-btn
                      >
                    </div>
                    <div class="d-flex justify-end">
                      <v-btn
                        outlined
                        color="primary"
                        class="mr-2 rounded-lg text-capitalize"
                        @click="activeShowProgramacion = true"
                      >
                        Ver programación</v-btn
                      >
                      <v-btn
                        outlined
                        color="primary"
                        class="mr-2 rounded-lg text-capitalize"
                        @click="activeDialogoDeleteProgramacion = true"
                      >
                        Eliminar programación</v-btn
                      >
                    </div>
                  </div>
                  <!-- Diálogo desplegado al seleccionar "Eliminar agenda" -->
                  <v-dialog
                    v-model="activeDialogoDeleteProgramacion"
                    max-width="500px"
                    class="ma-8"
                    persistent
                  >
                    <v-card class="rounded-xl">
                      <div class="pa-5">
                        <div class="d-flex align-center mb-4">
                          <v-card-title class="title-1 text--center"
                            >Opciones de eliminación
                          </v-card-title>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            @click="activeDialogoDeleteProgramacion = false"
                            icon
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon dense v-bind="attrs" v-on="on">
                                  <v-icon color="primary">mdi-close</v-icon>
                                </v-btn>
                              </template>
                              <span>Cerrar</span>
                            </v-tooltip>
                          </v-btn>
                        </div>
                        <!-- Rango de fecha -->
                        <div class="d-flex">
                          <v-menu
                            ref="menuRangeDeleteProgramacion"
                            v-model="menuRangeProgramacion"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="dateRangeDeleteProgramacion"
                                label="Rango de fecha"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <!-- Selección de rango de eliminación -->
                            <v-date-picker
                              range
                              v-model="dateRangeDeleteProgramacion"
                              no-title
                              scrollable
                            >
                              <v-spacer></v-spacer>
                              <!-- Acciones dentro de la selección del rango de fecha -->
                              <v-btn
                                text
                                class="text-capitalize"
                                color="primary"
                                @click="menuRangeProgramacion = false"
                              >
                                Cancelar
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                class="text-capitalize"
                                @click="
                                  $refs.menuRangeDeleteProgramacion.save(
                                    dateRangeDeleteProgramacion
                                  )
                                "
                              >
                                Seleccionar
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                          <!-- Botones de eliminación -->
                          <div>
                            <v-btn
                              color="red"
                              v-if="activePreventDeleteRangeProgramacion"
                              outlined
                              @click="btnDeleteProgramacionByRange"
                              :loading="
                                activeLoadingDeleteProgramacionByRutRange
                              "
                              class="mt-2 ml-4 text-capitalize"
                            >
                              Confirmar. borrar rango</v-btn
                            >
                            <v-btn
                              color="primary"
                              outlined
                              v-else
                              @click="
                                activePreventDeleteRangeProgramacion = true
                              "
                              class="mt-2 ml-4 text-capitalize"
                              >Borrar rango de fecha
                            </v-btn>
                          </div>
                        </div>
                        <v-divider class="mx-4 my-4"></v-divider>
                        <div class="d-flex justify-end mb-5">
                          <div>
                            <v-btn
                              color="red"
                              v-if="activePreventDeleteAllProgramacion"
                              outlined
                              class="text-capitalize"
                              @click="btnDeleteProgramacionByRut"
                              :loading="activeLoadingDeleteProgramacionByRut"
                              >Confirmar. borrar toda la programación</v-btn
                            >
                            <v-btn
                              v-else
                              color="primary"
                              class="text-capitalize"
                              @click="activePreventDeleteAllProgramacion = true"
                              >Borrar toda la programación
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </v-dialog>
                  <!-- Tabla de agenda con horarios -->
                  <v-row v-if="activeShowProgramacion" class="mt-5">
                    <v-card width="100%" class="pa-3 mx-2 mb-5 rounded-xl">
                      <div class="d-flex justify-end">
                        <v-btn @click="activeShowProgramacion = false" icon>
                          <v-icon color="primary">mdi-close</v-icon>
                        </v-btn>
                      </div>
                      <v-card-title>
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Buscar"
                          single-line
                          hide-details
                        >
                        </v-text-field>
                      </v-card-title>

                      <v-data-table
                        :headers="headersProgramacion"
                        :items="programaciones"
                        :search="search"
                        :footer-props="{
                          'items-per-page-text': 'Filas por página',
                        }"
                      >
                        <template v-slot:item.State="{ item }">
                          <v-chip color="primary" outlined class="ma-1">{{
                            item.State
                          }}</v-chip>
                        </template>
                        <template v-slot:item.Date="{ item }">
                          {{ setDateString(item.Date) }}
                        </template>
                        <template v-slot:item.action="{ item }">
                          <v-btn
                            icon
                            @click="btnDeleteProgramacionById(item.Id)"
                          >
                            <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:no-data>
                          <v-alert :value="true">
                            No hay resultados disponibles
                          </v-alert>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-row>
                </div>

                <!-- Chips con información de los horarios -->
              </div>
            </div>
            <!-- Vista de pendiente de selección -->
            <div v-else class="d-flex justify-center pa-3 ma-3">
              <div class="d-flex flex-column">
                <v-avatar tile size="300">
                  <v-img contain sizes="200" src="/icons/notselect.svg"></v-img>
                </v-avatar>
                <div class="d-flex justify-center">
                  <h3 class="mt-3 ml-3">
                    Debe seleccionar a un doctor primero
                  </h3>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-form>

      <!-- Alertas -->
      <div>
        <v-snackbar
          color="primary"
          v-model="successCreateDoctor"
          multiLine
        >
          Doctor creado con éxito
        </v-snackbar>
        <v-snackbar
          color="primary"
          v-model="successCreateHorariopersonal"
          multiLine
        >
          Día creado con éxito
        </v-snackbar>
        <v-snackbar
          color="primary"
          v-model="successCreateProgramacion"
          multiLine
        >
          Programación creado con éxito
        </v-snackbar>
        <v-snackbar
          color="primary"
          v-model="successDeleteHorariopersonal"
          multiLine
        >
          Eliminado con éxito
        </v-snackbar>
        <v-snackbar
          color="primary"
          v-model="successDeleteProgramacionById"
          multiLine
        >
          Eliminada programación con éxito
        </v-snackbar>
      </div>
    </div>
    <div v-else class="d-flex justify-center pa-3 ma-3">
      <div class="d-flex flex-column">
        <v-avatar tile size="300" class="d-flex justify-center">
          <v-img contain sizes="200" src="/icons/notselect.svg"></v-img>
        </v-avatar>
        <div class="d-flex justify-center">
          <h3 class="mt-3 ml-3">
            No los permisos necesarios para <br />
            acceder a los datos
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      rut: "",
      search: "",
      error: "",
      headers: [
        {
          text: "Rut",
          align: "start",
          filterable: false,
          value: "Rut",
        },
        { text: "Nombre", value: "Nombre" },
        { text: "Especialidad", value: "Especialidad" },
        { text: "Email", value: "Email" },
        { text: "Acciones", value: "action" },
      ],
      headersProgramacion: [
        {
          text: "Fecha",
          align: "start",
          value: "Date",
        },
        { text: "Día", value: "Dia" },
        { text: "Hora", value: "Time" },
        { text: "Estado", value: "State" },
        { text: "", value: "action" },
      ],

      minweek: 1,
      menu2: false,
      selection: "10:00",
      sizes: [
        "Lunes 10:00 a 16:00",
        "Martes 10:00 a 16:00",
        "Miercoles 10:00 a 16:00",
        "Jueves 10:00 a 16:00",
        "Viernes 10:00 a 16:00",
        "Sabado 10:00 a 16:00",
      ],
      selectDoctor: "",
      timeInterval: [
        { text: "15 min", value: 15 },
        { text: "30 min", value: 30 },
        { text: "45 min", value: 45 },
        { text: "1 hr", value: 60 },
      ],
      days: [
        {
          name: "Lunes",
          number: "1",
        },
        {
          name: "Martes",
          number: "2",
        },
        {
          name: "Miércoles",
          number: "3",
        },
        {
          name: "Jueves",
          number: "4",
        },
        {
          name: "Viernes",
          number: "5",
        },
        {
          name: "Sábado",
          number: "6",
        },
        {
          name: "Domingo",
          number: "7",
        },
      ],
      menuQuantityWeek: false,
      date: "",
      menuStartTime: false,
      menuEndTime: false,
      menuStartTimePrograming: false,
      menuEndTimePrograming: false,
      selectSucursalProgramacion: "",
      activeUploadingCreateDoctor: false,
      //validación del formulario de creación de doctor
      isFormValid: false,
      errorForm: false,
      errorMessages: "",
      rules: {
        required: (value) => (value && Boolean(value)) || "Campo requerido",
      },
      imgdoctorsrc: "",
      //gestion de horario

      selectInterval: "",
      timeweek: 1,
      selectDay: "",
      dateInicio: "",
      timeStart: "",
      timeEnd: "",
      activeUploadingCreateHorariopersonal: false,
      activeUploadingCreateProgramacion: false,
      activeShowProgramacion: false,

      //borrar dia seleccioando
      activeDialogoDeleteHorarioByRutVar: false,
      activeDialogoDeleteHorarioByRut: false,
      selectDeleteHorariopersonal: "",

      //programacion
      activeDialogoDeleteProgramacion: false,
      dateRangeDeleteProgramacion: [],
      menuRangeProgramacion: false,
      activeLoadingDeleteProgramacionByRutRange: false,
      activePreventDeleteRangeProgramacion: false,
      activePreventDeleteAllProgramacion: false,
      activeLoadingDeleteProgramacionByRut: false,
      activeShowDoctores: false,
    };
  },
  watch: {
    rut: function (val) {
      this.rut = this.cleanRut(this.rut);
      let len = this.rut.length - 1;
      if (len > 3) {
        let dv = this.rut[len];
        let beforeDv = this.rut
          .substring(0, len)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        this.rut = beforeDv + "-" + dv;
      }
    },
    successCreateDoctor: function (val) {
      if (val) {
        // this.doctor.Nombre = "";
        // this.rut = "";
        // this.doctor.Direccion = "";
        // this.doctor.Fono = "";
        // this.doctor.Descripcion = "";
        // this.doctor.Fecha_ingreso = "";
        // this.doctor.Email = "";
        // this.doctor.Especialidad = "";
        // // this.selectDoctor = "";
        this.activeUploadingCreateDoctor = false;
      }
    },
    selectDoctor: async function (val) {
      await this.getHorariospersonalByrut({ rut: val });
      await this.getProgramacionesByrut({ rut: val });
      if (val == "") {
        this.rut = "";
        this.actionSelectDoctor({
          Nombre: "",
          Rut: "",
          Direccion: "",
          Fono: "",
          Comi: "0",
          Fecha_ingreso: "",
          Horariospersonal: "",
          Estado: 0,
          Especialidad: "No Registra",
          Imagen: "n",
          Descripcion: "",
          Email: "",
        });
      }
    },
    successCreateHorariopersonal: function (val) {
      if (val) {
        this.activeUploadingCreateHorariopersonal = false;
        this.selectDay = "";
        this.timeStart = "";
        this.timeEnd = "";
      }
    },
    successCreateProgramacion: function (val) {
      if (val) {
        this.activeUploadingCreateProgramacion = false;
        this.selectInterval = "";
        this.timeweek = "";
        this.dateInicio = "";
        this.selectDay = "";
        this.timeStart = "";
        this.timeEnd = "";
      }
    },
    successDeleteProgramacionById: function (val) {
      if (val) {
        this.activeLoadingDeleteProgramacionByRut = false;
        this.activeLoadingDeleteProgramacionByRutRange = false;
        this.activeDialogoDeleteProgramacion = false;
      }
    },
  },
  methods: {
    ...mapActions("medical-module", [
      "postDoctor",
      "getHorariospersonalByrut",
      "actionSelectDoctor",
      "postHorariopersonal",
      "postProgramacion",
      "getProgramacionesByrut",
      "deleteHorariopersonal",
      "deleteProgramacionById",
      "deleteProgramacionByRutDateRange",
    ]),
    cleanRut(value) {
      return value.replace(/^0+|[^0-9kK]+/g, "").toUpperCase();
    },
    resetErrors() {
      this.errorForm = false;
      this.errorMessages = "";
    },
    validateRut() {
      let rut = this.cleanRut(this.rut);
      let digito_verificador = this.rut.substr(-1);

      rut = rut.substr(0, rut.length - 1);

      let serie = 0;
      let producto = 0;

      do {
        producto += (rut % 10) * ((serie % 6) + 2);
        serie++;
      } while ((rut = Math.floor(rut / 10)));

      let resto = producto % 11;
      let resultado = 11 - resto;

      if (resultado == 11) {
        resultado = 0;
      } else if (resultado == 10) {
        resultado = "K";
      }

      if (digito_verificador != resultado) {
        this.errorInRut();
      } else {
        this.error = "";
      }
    },
    errorInRut() {
      this.error = "RUT Inválido";
      this.$refs.rut.focus();
    },
    getUrlVariant() {
      let uri = URL.createObjectURL(this.imgdoctorsrc);
      return uri;
    },
    btnCreateDoctor() {
      if (this.$refs.form.validate()) {
        this.activeUploadingCreateDoctor = true;
     
        this.doctor.Rut = this.rut.replaceAll(".", "");
        let update = false;
        if (this.selectDoctor) {
          update = true;
        }
        this.doctor.Fecha_ingreso = this.doctor.Fecha_ingreso + " 00:00:00";

        this.postDoctor({
          doctor: this.doctor,
          update: update,
          imgsrc: this.imgdoctorsrc,
        });
      }
    },
    cleanDoctor() {
      this.selectDoctor = "";
    },
    btnSelectDoctorEditTime(doctor) {
      this.selectDoctor = doctor.Rut;
      this.rut = doctor.Rut;
      let finddoctor = this.doctors.find(
        (item) => item.Rut == this.selectDoctor
      );
      finddoctor.Fecha_ingreso = finddoctor.Fecha_ingreso.substring(0, 10);
      this.actionSelectDoctor(finddoctor);
      this.activeShowDoctores = false;
    },
    btnCrearhorariopersonal() {
      this.activeUploadingCreateHorariopersonal = true;
      let horariopersonal = {
        Id: 0,
        Dia: this.days.find((item) => item.number == this.selectDay).name,
        Desde:
          new Date().toISOString().substring(0, 10) +
          " " +
          this.timeStart +
          ":00",
        Hasta:
          new Date().toISOString().substring(0, 10) +
          " " +
          this.timeEnd +
          ":00",
        Colaciondesde:
          new Date().toISOString().substring(0, 10) +
          " " +
          this.timeStart +
          ":00",
        Colacionhasta:
          new Date().toISOString().substring(0, 10) +
          " " +
          this.timeEnd +
          ":00",
        Rut: this.doctor.Rut,
        Nombre: this.doctor.Nombre,
        Fechadesde: new Date().toISOString().substring(0, 10),
        Fechahasta: new Date().toISOString().substring(0, 10),
        Var1: this.selectDay,
      };
     
      this.postHorariopersonal({ horariopersonal: horariopersonal });
    },
    addDays(date, days) {
      var result = date;
      result.setDate(result.getDate() + days);
      return result;
    },
    setDateNow(setdate) {
      var currentDateObj = setdate;
      var numberOfMlSeconds = currentDateObj.getTime();
      var addMlSeconds = 60 * 180000;
      var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
      return newDateObj;
    },
    addMinutes(date, minutes) {
      return new Date(date.getTime() + minutes * 60000);
    },
    btnCreateProgramacion() {
      this.activeUploadingCreateProgramacion = true;
      let day = new Date(this.dateInicio);

      let dif = 0;
      let desde = "";
      dif = parseInt(this.selectDay - (day.getDay() + 1));
      let sum = 0;
      if (dif < 0) {
        sum = dif + 7;
      } else {
        sum = dif;
      }
      desde = this.addDays(day, sum);

      let programaciones = [];
      //cicle for
      for (let step = 0; step < this.timeweek; step++) {
        var a = new Date("2022-10-10T" + this.timeStart + ":00");
        a = this.setDateNow(a);
        var b = new Date("2022-10-10T" + this.timeEnd + ":00");
        b = this.setDateNow(b);
        var c = (a - b) / 1000;
        let minutos = (c / 60) * -1; // resultado 5;
        let ciclos = minutos / this.selectInterval;
        for (let step = 0; step < ciclos; step++) {
          // Runs 5 times, with values of step 0 through 4.
          let prorgamacion = {
            Rut: this.doctor.Rut,
            Date: desde.toISOString().substring(0, 10),
            Time: a.toISOString().substring(11, 16),
            State: "Libre",
            Token: "T",
            Link: "https://",
            Dia: this.days.find((item) => item.number == this.selectDay).name,
            Sucursal: this.selectSucursalProgramacion,
          };
          programaciones.push(prorgamacion);

          a = this.addMinutes(a, this.selectInterval);
        }
        desde = this.addDays(desde, 7);
      }
      this.postProgramacion({
        programaciones: programaciones,
        rut: this.doctor.Rut,
      });
    },
    setDateString(date) {
      var dataSet = new Date(date.substring(0, 10) + "T00:00:00");
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " del " + year;
    },
    btnSelectDeleteHorariopersonalByRutVar1(var1) {
      this.activeDialogoDeleteHorarioByRutVar = true;
      this.selectDeleteHorariopersonal = var1;
    },
    btnSelectDeleteHorariopersonalByRut() {
      this.activeDialogoDeleteHorarioByRut = true;
    },
    btnConfirmDeleteHorariopersonal() {
      this.deleteHorariopersonal({
        rut: this.doctor.Rut,
        var1: this.selectDeleteHorariopersonal,
      });
      this.activeDialogoDeleteHorarioByRut = false;
      this.activeDialogoDeleteHorarioByRutVar = false;
      this.selectDeleteHorariopersonal = "";
    },
    btnDeleteProgramacionById(id) {
      this.deleteProgramacionById({ id: id, rut: this.doctor.Rut });
    },
    btnDeleteProgramacionByRange() {
      if (this.dateRangeDeleteProgramacion.length > 0) {
        this.activeLoadingDeleteProgramacionByRutRange = true;
        let desde = this.dateRangeDeleteProgramacion[0];
        let hasta = this.dateRangeDeleteProgramacion[1];
        this.deleteProgramacionByRutDateRange({
          rut: this.doctor.Rut,
          desde: desde,
          hasta: hasta,
        });
      } else {
        alert("Debe seleccionar el rango");
      }
    },
    btnDeleteProgramacionByRut() {
      this.activeLoadingDeleteProgramacionByRut = true;
      let desde = "";
      let hasta = "";
      this.deleteProgramacionByRutDateRange({
        rut: this.doctor.Rut,
        desde: desde,
        hasta: hasta,
      });
    },
    async searchDefaultDoctor() {
      if (this.licen.Var37 == 0) {
        this.selectDoctor = this.doctor.Rut;
        await this.getHorariospersonalByrut({ rut: this.doctor.Rut });
        await this.getProgramacionesByrut({ rut: this.doctor.Rut });
        this.rut = this.doctor.Rut;
      }
    },
  },
  computed: {
    ...mapState("medical-module", [
      "doctors",
      "especialidades",
      "doctor",
      "successCreateDoctor",
      "activefindsuccesshorarios",
      "successCreateHorariopersonal",
      "successCreateProgramacion",
      "successDeleteHorariopersonal",
      "programaciones",
      "successDeleteProgramacionById",
    ]),
    ...mapState("auth-module", ["licen", "sedes"]),
    minTimeEnd() {
      var b = new Date("2022-10-10T" + this.timeStart + ":00");
      b = this.setDateNow(b);
      let newdate = this.addMinutes(b, this.selectInterval);
      return newdate.toISOString().substring(11, 16);
    },
  },
  created() {
    this.searchDefaultDoctor();
  },
};
</script>
<style>
/* Eliminar select Sort By desplegado en vista móvil de tabla  */
.v-data-table-header-mobile__wrapper {
  display: none;
  border-style: none;
}

.v-data-table-header-mobile {
  display: none;
}
/* Subtítulos del color primario */
h3 {
  color: #0d47a1;
}
/* Cambio de color al h3 */
.black_h3 {
  color: black;
}
/* RWD acciones de gestión de horarios */
@media only screen and (max-width: 600px) {
  .btnContainer {
    display: flex;
    flex-direction: column;
  }
}
</style>
